import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
class Request {

  constructor(private http: HttpClient) { }
  baseurl = "https://api.tamimi.co/api/v1";
  //baseurl = "http://localhost:8080/api/v1";
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': sessionStorage.getItem('user')
      // 'Authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MTUxOThlYmNmNzhiYjAyZGExYTk3NzciLCJpYXQiOjE2NDA2MDI2MDMsImV4cCI6MTY0MTIwNzQwM30.nsvSqb38_bA_rG-vxd394RNErCQi_Pftqz6tZhuxfwY"
    })
  }
  httpOptionsFormdata = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
      'Authorization': sessionStorage.getItem('user')
    })
  }
}
export { Request }